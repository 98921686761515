import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'
import { reactive, onMounted, ref } from '@vue/composition-api'
import { createFolder } from '@/helper/damUtils'
import { v4 as uuidv4 } from 'uuid'

export default (ctx) => {
  const currentLang = ctx.root.$store.state.locale.currentLang

  const btnSubmitLabel = ref('Submit')

  const formData = reactive({
    id: undefined,
    uuid: '',
    status: true,
    created_by: null,
    updated_by: null,
    updated_at: null,
    house_style_id: '',
    property_category_id: '',
    house_model_floor_three_description: '',
    house_model_floor_two_description: '',
    house_model_floor_one_description: '',
    house_model_title: '',
    house_model_area: '',
    house_model_code: '',
    house_model_video_type: 'youtube',
    house_model_functions: reactive({}),
    house_model_gallery: [],
    house_model_image: [],
    house_model_floor_three_image: [],
    house_model_floor_two_image: [],
    house_model_floor_one_image: [],
    house_model_image_360_url: '',
    house_model_image_360_thumbnail: [],
    house_model_3d_floor_url: '',
    house_model_3d_floor_thumbnail: [],
    house_model_video_url: '',
    house_model_video_thumbnail: [],
    house_model_video_file: '',
  })

  const getMediaByAttributeCode = (houseModelFiles = [], attributeCode) => {
    return houseModelFiles.filter((file) => file.attribute_code === attributeCode) || []
  }

  const prepareFormData = (result, customLang) => {
    const lang = !customLang ? currentLang : customLang

    const houseModelFiles = get(result, 'house_model_files', [])
    formData.id = result.id
    formData.updated_at = result.updated_at
    formData.created_by = result.created_by
    formData.updated_by = result.updated_by
    formData.status = result.status === 'published'
    formData.house_style_id = get(result, 'house_serie.id', '')
    formData.property_category_id = get(result, 'property_category_id', '')
    formData.house_model_folder_name = get(result, [lang, 'house_model_folder_name'])

    formData.house_model_floor_three_description = get(
      result,
      [lang, 'house_model_floor_three_description'],
      '',
    )
    formData.house_model_floor_two_description = get(
      result,
      [lang, 'house_model_floor_two_description'],
      '',
    )
    formData.house_model_floor_one_description = get(
      result,
      [lang, 'house_model_floor_one_description'],
      '',
    )
    formData.house_model_title = get(result, [lang, 'house_model_title'], '')
    formData.house_model_area = get(result, [lang, 'house_model_area'], '')
    formData.house_model_code = get(result, [lang, 'house_model_code'], '')
    formData.house_model_video_type = get(result, [lang, 'house_model_video_type'], '')
    formData.house_model_functions = get(result, 'house_model_functions', []).reduce(
      (hf, item) => ({ ...hf, ...{ [item.house_function_id]: item.value } }),
      {},
    )
    
    const houseModelGallery = getMediaByAttributeCode(
      houseModelFiles,
      'house_model_gallery',
    )
    formData.house_model_gallery = orderBy(houseModelGallery, ['image_index'], ['asc'])

    const houseModelImage = getMediaByAttributeCode(
      houseModelFiles,
      'house_model_image',
    )
    formData.house_model_image = orderBy(houseModelImage, ['image_index'], ['asc'])
    formData.house_model_floor_three_image = getMediaByAttributeCode(
      houseModelFiles,
      'house_model_floor_three_image',
    )
    formData.house_model_floor_two_image = getMediaByAttributeCode(
      houseModelFiles,
      'house_model_floor_two_image',
    )
    formData.house_model_floor_one_image = getMediaByAttributeCode(
      houseModelFiles,
      'house_model_floor_one_image',
    )
    formData.house_model_image_360_url = get(
      result,
      [lang, 'house_model_image_360_url'],
      '',
    )
    formData.house_model_image_360_thumbnail = getMediaByAttributeCode(
      houseModelFiles,
      'house_model_image_360_thumbnail',
    )

    formData.house_model_3d_floor_url = get(
      result,
      [lang, 'house_model_3d_floor_url'],
      '',
    )
    formData.house_model_3d_floor_thumbnail = getMediaByAttributeCode(
      houseModelFiles,
      'house_model_3d_floor_thumbnail',
    )

    formData.house_model_video_url = get(result, [lang, 'house_model_video_url'], '')
    formData.house_model_video_thumbnail = getMediaByAttributeCode(
      houseModelFiles,
      'house_model_video_thumbnail',
    )
    formData.house_model_video_file = getMediaByAttributeCode(
      houseModelFiles,
      'house_model_video_file',
    )
  }

  const fetchByRouteId = async () => {
    const uuid = get(ctx.root.$router.currentRoute.params, 'id', null)
    const folders = ['Floor One', 'Floor Two', 'Floor Three', 'Image 360', 'VDO Thumbnail', 'VDO', 'Gallery', 'House Image', '3D']
    if (uuid) {
      btnSubmitLabel.value = 'Update'
      formData.uuid = uuid
      ctx.root.$vs.loading()
      await ctx.root.$store
        .dispatch('houseModel/fetchOne', uuid)
        .then((result) => {
          const currentLang = get(result, 'lang', 'th')
          ctx.root.$store.dispatch('locale/changeLang', currentLang)
          prepareFormData(result, currentLang)
          if (!formData.house_model_folder_name) {
            const randomUuid = uuidv4()
            formData.house_model_folder_name = randomUuid
          }
          return createFolder(`house-model/${formData.house_model_folder_name}`, folders)
        })
        .then(() => ctx.root.$vs.loading.close())
        .catch((error) => {
          console.log(error)
        })
    } else {
      const randomUuid = uuidv4()
      ctx.root.$vs.loading()
      formData.house_model_folder_name = randomUuid
      await createFolder(`house-model/${randomUuid}`, folders)
      ctx.root.$vs.loading.close()
    }
  }

  const getHouseStyleList = () => {
    return ctx.root.$store.dispatch('houseSerie/fetch')
  }

  const getPropertyListCategory = () => {
    return ctx.root.$store.dispatch('category/fetchDataListItems', 'th')
  }

  const getHouseFunctionList = () => {
    return ctx.root.$store.dispatch('houseFunction/fetch')
  }

  const getHouseFunctionLangList = () => {
    return ctx.root.$store.dispatch('houseFunction/fetchByLang', 'th')
  }

  const serializeFormData = (formData) => {
    const imageKeys = [
      'house_model_gallery',
      'house_model_image',
      'house_model_floor_three_image',
      'house_model_floor_two_image',
      'house_model_floor_one_image',
      'house_model_image_360_thumbnail',
      'house_model_3d_floor_thumbnail',
      'house_model_video_thumbnail',
      'house_model_video_file',
    ]

    const activeUser = ctx.root.$store.state.AppActiveUser
    const email = get(activeUser, 'email', '')
    let createdBy = formData.created_by
    const updatedBy = email

    // add user created by
    if (!createdBy) {
      createdBy = email
    }

    return {
      ...formData,
      created_by: createdBy,
      updated_by: updatedBy,
      status: formData.status ? 'published' : 'drafted',
      house_model_functions: Object.keys(formData.house_model_functions).map((id) => ({
        house_function_id: id,
        value: formData.house_model_functions[id],
      })),
      house_model_files: imageKeys.reduce((files, imageKey) => {
        const images = get(formData, [imageKey], [])
        if (isEmpty(images)) {
          return files
        }

        return [
          ...files,
          ...images.map((image) => ({
            attribute_code: imageKey,
            ...image,
          })),
        ]
      }, []),
    }
  }

  const fetchInfoAll = async () => {
    await getHouseStyleList()
    await getPropertyListCategory()
    await getHouseFunctionLangList()
    await getHouseFunctionList()
  }

  onMounted(() => {
    fetchByRouteId()
    fetchInfoAll()
  })

  return {
    formData,
    prepareFormData,
    fetchByRouteId,
    serializeFormData,
    btnSubmitLabel,
    fetchInfoAll,
    getHouseStyleList,
    getPropertyListCategory,
    getHouseFunctionList
  }
}
