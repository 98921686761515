var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(context){return [_c('vx-card',{staticClass:"mb-base"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit(context)}}},[_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("เลือกสไตล์บ้าน")]),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"สไตล์บ้าน","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"w-3/12"},[_c('vs-select',{attrs:{"name":"house_style_id","icon":"arrow_downward","autocomplete":""},model:{value:(_vm.formData.house_style_id),callback:function ($$v) {_vm.$set(_vm.formData, "house_style_id", $$v)},expression:"formData.house_style_id"}},_vm._l((_vm.houseSeries),function(item,index){return _c('vs-select-item',{key:index,attrs:{"value":item.id,"text":item.house_serie_title}})}),1)],1),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("รหัสแบบบ้าน")]),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"รหัสแบบบ้าน","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-3/12",class:{ 'border border-danger': errors.length > 0 },model:{value:(_vm.formData.house_model_code),callback:function ($$v) {_vm.$set(_vm.formData, "house_model_code", $$v)},expression:"formData.house_model_code"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("ระบุชื่อแบบบ้าน")]),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"ชื่อแบบบ้าน","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-3/12",class:{ 'border border-danger': errors.length > 0 },model:{value:(_vm.formData.house_model_title),callback:function ($$v) {_vm.$set(_vm.formData, "house_model_title", $$v)},expression:"formData.house_model_title"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("พื้นที่ใช้สอย")]),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])]),_c('FormContent',{staticClass:"flex space-x-2"},[_c('div',{staticClass:"w-3/12"},[_c('ValidationProvider',{attrs:{"name":"พื้นที่ใช้สอย","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('lh-input-number',{staticClass:"w-full",class:{ 'border border-danger': errors.length > 0 },model:{value:(_vm.formData.house_model_area),callback:function ($$v) {_vm.$set(_vm.formData, "house_model_area", $$v)},expression:"formData.house_model_area"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                  "+_vm._s(errors[0])+"\n                ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"flex items-center"},[_vm._v("\n              ตารางเมตร\n            ")])])],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("ประเภทบ้าน")]),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"ประเภทบ้าน","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"w-3/12"},[_c('vs-select',{attrs:{"name":"property_category_id","icon":"arrow_downward","autocomplete":""},model:{value:(_vm.formData.property_category_id),callback:function ($$v) {_vm.$set(_vm.formData, "property_category_id", $$v)},expression:"formData.property_category_id"}},_vm._l((_vm.propertyCategories),function(item,index){return _c('vs-select-item',{key:index,attrs:{"value":item.id,"text":item.project_category_name}})}),1)],1),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("ระบุฟังก์ชันของบ้าน")]),_c('br'),_c('span',[_vm._v("(ถ้าไม่มีไม่ต้องระบุ ห้ามใส่ 0)")])]),_c('FormContent')],1),_vm._l((_vm.houseFunctionList),function(item){return _c('div',{key:item.id,staticClass:"vx-row mb-6"},[_c('div',{staticClass:"vx-col w-full md:w-3/12 md:text-right pt-2"},[_vm._v("\n            "+_vm._s(item.house_function_title)+"\n            "),(item.is_required)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),_c('div',{staticClass:"vx-col w-full md:w-6/12"},[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col md:w-2/12"},[_c('ValidationProvider',{attrs:{"name":item.house_function_title,"rules":item.is_required ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('lh-input-number',{staticClass:"w-full",model:{value:(_vm.formData.house_model_functions[item.id]),callback:function ($$v) {_vm.$set(_vm.formData.house_model_functions, item.id, $$v)},expression:"formData.house_model_functions[item.id]"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                  "+_vm._s(errors[0])+"\n                ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"vx-col md:w-7/12 flex items-center"},[_vm._v("\n                "+_vm._s(item.house_function_unit)+"\n              ")])])])])}),_c('vs-divider'),_c('FormLayout',[_c('div',{staticClass:"w-full border-t border-danger mb-4 px-10"},[_vm._v("\n            ภาพแบบบ้าน (.JPG , .PNG , .GIF) กรุณาเลือกภาพที่ต้องการเป็นภาพหลัก 1 ภาพ\n            "),_c('br'),_c('span',{staticClass:"font-bold"},[_vm._v("ขนาดรูปจะต้องมีสัดส่วน (Ratio) 16 : 9")]),_c('br'),_c('span',{staticClass:"text-warning"},[_vm._v("ขนาดรูปแนะนำ 1920 x 1080")])]),_c('div',{staticClass:"w-full px-10"},[_c('ValidationProvider',{attrs:{"name":"ภาพแบบบ้าน","rules":"lh_ratio:16,9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('GalleryForm',{attrs:{"folder":(_vm.baseFolder + "/house-model/" + (_vm.formData.house_model_folder_name) + "/House Image"),"has-alt-text":"","has-selectable":"","selection-name":"house-model-image","selectRadioLabel":"เลือกเป็นภาพหลัก"},model:{value:(_vm.formData.house_model_image),callback:function ($$v) {_vm.$set(_vm.formData, "house_model_image", $$v)},expression:"formData.house_model_image"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]):_vm._e()]}}],null,true)})],1)]),_c('vs-divider'),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("\n              ภาพ Floor Plan ชั้น 1\n            ")]),_c('br'),_c('span',{staticClass:"font-bold"},[_vm._v("ขนาดรูปจะต้องมีสัดส่วน (Ratio) 16 : 9")]),_c('br'),_c('span',{staticClass:"text-warning"},[_vm._v("ขนาดรูปแนะนำ 1920 x 1080")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"ภาพ Floor Plan ชั้น 1","rules":"lh_ratio:16,9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ImageUpload',{attrs:{"folder":(_vm.baseFolder + "/house-model/" + (_vm.formData.house_model_folder_name) + "/Floor One"),"has-delete-button":!_vm.isEmpty(_vm.formData.house_model_floor_one_image),"has-alt-text":"","selection-name":"house_model_floor_one_image"},model:{value:(_vm.formData.house_model_floor_one_image),callback:function ($$v) {_vm.$set(_vm.formData, "house_model_floor_one_image", $$v)},expression:"formData.house_model_floor_one_image"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("บรรยาย Floor Plan ชั้น 1")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"บรรยาย Floor Plan ชั้น 1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-textarea',{staticClass:"w-full",class:{ 'border border-danger': errors.length > 0 },attrs:{"rows":"12"},model:{value:(_vm.formData.house_model_floor_one_description),callback:function ($$v) {_vm.$set(_vm.formData, "house_model_floor_one_description", $$v)},expression:"formData.house_model_floor_one_description"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("ภาพ Floor Plan ชั้น 2")]),_c('br'),_c('span',{staticClass:"font-bold"},[_vm._v("ขนาดรูปจะต้องมีสัดส่วน (Ratio) 16 : 9")]),_c('br'),_c('span',{staticClass:"text-warning"},[_vm._v("ขนาดรูปแนะนำ 1920 x 1080")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"ภาพ Floor Plan ชั้น 2","rules":"lh_ratio:16,9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ImageUpload',{attrs:{"folder":(_vm.baseFolder + "/house-model/" + (_vm.formData.house_model_folder_name) + "/Floor Two"),"has-alt-text":"","has-delete-button":!_vm.isEmpty(_vm.formData.house_model_floor_two_image),"selection-name":"house_model_floor_two_image"},model:{value:(_vm.formData.house_model_floor_two_image),callback:function ($$v) {_vm.$set(_vm.formData, "house_model_floor_two_image", $$v)},expression:"formData.house_model_floor_two_image"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("บรรยาย Floor Plan ชั้น 2")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"บรรยาย Floor Plan ชั้น 2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-textarea',{staticClass:"w-full",class:{ 'border border-danger': errors.length > 0 },attrs:{"rows":"12"},model:{value:(_vm.formData.house_model_floor_two_description),callback:function ($$v) {_vm.$set(_vm.formData, "house_model_floor_two_description", $$v)},expression:"formData.house_model_floor_two_description"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("ภาพ Floor Plan ชั้น 3")]),_c('br'),_c('span',{staticClass:"font-bold"},[_vm._v("ขนาดรูปจะต้องมีสัดส่วน (Ratio) 16 : 9")]),_c('br'),_c('span',{staticClass:"text-warning"},[_vm._v("ขนาดรูปแนะนำ 1920 x 1080")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"ภาพ Floor Plan ชั้น 3","rules":"lh_ratio:16,9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ImageUpload',{attrs:{"folder":(_vm.baseFolder + "/house-model/" + (_vm.formData.house_model_folder_name) + "/Floor Three"),"has-alt-text":"","has-delete-button":!_vm.isEmpty(_vm.formData.house_model_floor_three_image),"selection-name":"house_model_floor_three_image"},model:{value:(_vm.formData.house_model_floor_three_image),callback:function ($$v) {_vm.$set(_vm.formData, "house_model_floor_three_image", $$v)},expression:"formData.house_model_floor_three_image"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("บรรยาย Floor Plan ชั้น 3")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"บรรยาย Floor Plan ชั้น 3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-textarea',{staticClass:"w-full",class:{ 'border border-danger': errors.length > 0 },attrs:{"rows":"12"},model:{value:(_vm.formData.house_model_floor_three_description),callback:function ($$v) {_vm.$set(_vm.formData, "house_model_floor_three_description", $$v)},expression:"formData.house_model_floor_three_description"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("URL 360 Virtual Tour")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"URL 360 Virtual Tour"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",class:{ 'border border-danger': errors.length > 0 },model:{value:(_vm.formData.house_model_image_360_url),callback:function ($$v) {_vm.$set(_vm.formData, "house_model_image_360_url", $$v)},expression:"formData.house_model_image_360_url"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("360 Cover image")]),_c('br'),_c('span',{staticClass:"font-bold"},[_vm._v("ขนาดรูปจะต้องมีสัดส่วน (Ratio) 16 : 9")]),_c('br'),_c('span',{staticClass:"text-warning"},[_vm._v("ขนาดรูปแนะนำ 1920 x 1080")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"house_model_image_360_thumbnail","rules":"lh_mimes:jpg,png,gif|lh_ratio:16,9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ImageUpload',{attrs:{"folder":(_vm.baseFolder + "/house-model/" + (_vm.formData.house_model_folder_name) + "/Image 360"),"has-delete-button":!_vm.isEmpty(_vm.formData.house_model_image_360_thumbnail),"selection-name":"house_model_image_360_thumbnail"},model:{value:(_vm.formData.house_model_image_360_thumbnail),callback:function ($$v) {_vm.$set(_vm.formData, "house_model_image_360_thumbnail", $$v)},expression:"formData.house_model_image_360_thumbnail"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("URL 3D Floor Plan")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"URL 3D Floor Plan"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",class:{ 'border border-danger': errors.length > 0 },model:{value:(_vm.formData.house_model_3d_floor_url),callback:function ($$v) {_vm.$set(_vm.formData, "house_model_3d_floor_url", $$v)},expression:"formData.house_model_3d_floor_url"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("\n              เลือกภาพประกอบ 3D Floor Plan\n              "),_c('br'),_vm._v("(.jpg , .png , .gif)\n              "),_c('br'),_c('span',{staticClass:"font-bold"},[_vm._v("ขนาดรูปจะต้องมีสัดส่วน (Ratio) 16 : 9")]),_c('br'),_c('span',{staticClass:"text-warning"},[_vm._v("ขนาดรูปแนะนำ 1920 x 1080")])])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"house_model_3d_floor_thumbnail","rules":"lh_mimes:jpg,png,gif|lh_ratio:16,9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ImageUpload',{attrs:{"folder":(_vm.baseFolder + "/house-model/" + (_vm.formData.house_model_folder_name) + "/3D"),"has-delete-button":!_vm.isEmpty(_vm.formData.house_model_3d_floor_thumbnail),"selection-name":"house_model_3d_floor_thumbnail"},model:{value:(_vm.formData.house_model_3d_floor_thumbnail),callback:function ($$v) {_vm.$set(_vm.formData, "house_model_3d_floor_thumbnail", $$v)},expression:"formData.house_model_3d_floor_thumbnail"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("เลือกชนิดการอัพโหลด")])]),_c('FormContent',[_c('ul',[_c('li',[_c('vs-radio',{attrs:{"vs-name":"radios1","vs-value":"youtube"},model:{value:(_vm.formData.house_model_video_type),callback:function ($$v) {_vm.$set(_vm.formData, "house_model_video_type", $$v)},expression:"formData.house_model_video_type"}},[_vm._v("VDO Youtube\n                ")])],1),_c('li',[_c('vs-radio',{attrs:{"vs-name":"radios1","vs-value":"file"},model:{value:(_vm.formData.house_model_video_type),callback:function ($$v) {_vm.$set(_vm.formData, "house_model_video_type", $$v)},expression:"formData.house_model_video_type"}},[_vm._v("VDO File")])],1)])])],1),_c('FormLayout',{directives:[{name:"show",rawName:"v-show",value:(_vm.formData.house_model_video_type === 'youtube'),expression:"formData.house_model_video_type === 'youtube'"}]},[_c('FormLabel',[_c('span',[_vm._v("URL Youtube")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"rules":("" + (_vm.formData.house_model_video_type === 'youtube' ? 'url' : '')),"name":"URL Youtube"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"placeholder":"URL Youtube","name":"house_model_video_url"},model:{value:(_vm.formData.house_model_video_url),callback:function ($$v) {_vm.$set(_vm.formData, "house_model_video_url", $$v)},expression:"formData.house_model_video_url"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("Thumbnail(.jpg , .png , .gif)")]),_c('br'),_c('span',{staticClass:"font-bold"},[_vm._v("ขนาดรูปจะต้องมีสัดส่วน (Ratio) 16 : 9")]),_c('br'),_c('span',{staticClass:"text-warning"},[_vm._v("ขนาดรูปแนะนำ 1920 x 1080")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"house_model_video_thumbnail","rules":"lh_mimes:jpg,png,gif|lh_ratio:16,9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ImageUpload',{attrs:{"folder":(_vm.baseFolder + "/house-model/" + (_vm.formData.house_model_folder_name) + "/VDO Thumbnail"),"has-delete-button":!_vm.isEmpty(_vm.formData.house_model_video_thumbnail),"selection-name":"house_model_video_thumbnail"},model:{value:(_vm.formData.house_model_video_thumbnail),callback:function ($$v) {_vm.$set(_vm.formData, "house_model_video_thumbnail", $$v)},expression:"formData.house_model_video_thumbnail"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',{directives:[{name:"show",rawName:"v-show",value:(_vm.formData.house_model_video_type === 'file'),expression:"formData.house_model_video_type === 'file'"}]},[_c('FormLabel',[_c('span',[_vm._v("VDO (เฉพาะไฟล์ .mp4)")]),_c('br'),_c('span',{staticClass:"font-bold"},[_vm._v("ขนาดไฟล์ vdo ไม่เกิน 300 MB")]),_c('br')]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"Video","rules":"lh_mimes:mp4|lh_size:300_MB"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VdoUpload',{attrs:{"folder":(_vm.baseFolder + "/house-model/" + (_vm.formData.house_model_folder_name) + "/VDO"),"has-delete-button":!_vm.isEmpty(_vm.formData.house_model_video_file),"selection-name":"assets"},model:{value:(_vm.formData.house_model_video_file),callback:function ($$v) {_vm.$set(_vm.formData, "house_model_video_file", $$v)},expression:"formData.house_model_video_file"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]):_vm._e()]}}],null,true)})],1)],1),_c('vs-divider'),_c('FormLayout',[_c('div',{staticClass:"w-full px-10 mb-3 border-t border-danger"},[_vm._v("\n            Gallery แบบบ้าน (.JPG , .PNG , .GIF) ใส่ภาพน้อยสุด 5 ภาพ\n          ")]),_c('div',{staticClass:"w-full px-10"},[_c('ValidationProvider',{attrs:{"name":"รูปภาพหลักของหน้า","rules":"lh_ratio:16,9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('GalleryForm',{attrs:{"folder":(_vm.baseFolder + "/house-model/" + (_vm.formData.house_model_folder_name) + "/Gallery"),"has-alt-text":"","selection-name":"house-model-gallery"},model:{value:(_vm.formData.house_model_gallery),callback:function ($$v) {_vm.$set(_vm.formData, "house_model_gallery", $$v)},expression:"formData.house_model_gallery"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]):_vm._e()]}}],null,true)})],1)]),_c('vs-divider'),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("แสดงบนเว็ปไซต์")]),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])]),_c('div',{staticClass:"flex items-center vx-col w-full md:w-6/12"},[_c('vs-switch',{model:{value:(_vm.formData.status),callback:function ($$v) {_vm.$set(_vm.formData, "status", $$v)},expression:"formData.status"}})],1)],1)],2)]),_c('vx-card',[_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("แก้ไขล่าสุดโดย")])]),_c('div',{staticClass:"flex items-center vx-col w-full md:w-6/12"},[_c('span',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.formData.updated_by))])])],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("แก้ไขล่าสุดวันที่")])]),_c('div',{staticClass:"flex items-center vx-col w-full md:w-6/12"},[_c('span',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.formData.updated_at)))])])],1),_c('FormLayout',[_c('FormLabel'),_c('FormContent',[_c('vs-button',{on:{"click":function($event){return _vm.submit(context)}}},[_vm._v(_vm._s(_vm.btnSubmitLabel))])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }