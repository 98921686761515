<template>
  <div>
    <ValidationObserver v-slot="context">
      <vx-card class="mb-base">
        <form @submit.prevent="submit(context)">
          <FormLayout>
            <FormLabel>
              <span>เลือกสไตล์บ้าน</span><span class="text-danger"> *</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="สไตล์บ้าน" 
                rules="required">
                <div class="w-3/12">
                  <vs-select
                    v-model="formData.house_style_id"
                    name="house_style_id"
                    icon="arrow_downward"
                    autocomplete
                  >
                    <vs-select-item
                      v-for="(item, index) in houseSeries"
                      :value="item.id"
                      :text="item.house_serie_title"
                      :key="index"
                    />
                  </vs-select>
                </div>
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>

          <FormLayout>
            <FormLabel>
              <span>รหัสแบบบ้าน</span><span class="text-danger"> *</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="รหัสแบบบ้าน" 
                rules="required">
                <vs-input
                  :class="{ 'border border-danger': errors.length > 0 }"
                  v-model="formData.house_model_code"
                  class="w-3/12"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>

          <FormLayout>
            <FormLabel>
              <span>ระบุชื่อแบบบ้าน</span><span class="text-danger"> *</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="ชื่อแบบบ้าน" 
                rules="required">
                <vs-input
                  :class="{ 'border border-danger': errors.length > 0 }"
                  v-model="formData.house_model_title"
                  class="w-3/12"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>

          <FormLayout>
            <FormLabel>
              <span>พื้นที่ใช้สอย</span><span class="text-danger"> *</span>
            </FormLabel>
            <FormContent class="flex space-x-2">
              <div class="w-3/12">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="พื้นที่ใช้สอย"
                  rules="required"
                >
                  <lh-input-number
                    :class="{ 'border border-danger': errors.length > 0 }"
                    v-model="formData.house_model_area"
                    class="w-full"
                  />
                  <span
                    v-if="errors.length > 0"
                    class="text-danger text-sm">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
              </div>
              <div class="flex items-center">
                ตารางเมตร
              </div>
            </FormContent>
          </FormLayout>

          <FormLayout>
            <FormLabel>
              <span>ประเภทบ้าน</span><span class="text-danger"> *</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }" 
                name="ประเภทบ้าน" 
                rules="required">
                <div class="w-3/12">
                  <vs-select
                    v-model="formData.property_category_id"
                    name="property_category_id"
                    icon="arrow_downward"
                    autocomplete
                  >
                    <vs-select-item
                      v-for="(item, index) in propertyCategories"
                      :value="item.id"
                      :text="item.project_category_name"
                      :key="index"
                    />
                  </vs-select>
                </div>
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>

          <FormLayout>
            <FormLabel>
              <span>ระบุฟังก์ชันของบ้าน</span> <br>
              <span>(ถ้าไม่มีไม่ต้องระบุ ห้ามใส่ 0)</span>
            </FormLabel>
            <FormContent />
          </FormLayout>

          <div 
            v-for="item in houseFunctionList" 
            :key="item.id" 
            class="vx-row mb-6">
            <div class="vx-col w-full md:w-3/12 md:text-right pt-2">
              {{ item.house_function_title }}
              <span v-if="item.is_required" class="text-danger">*</span>
            </div>
            <div class="vx-col w-full md:w-6/12">
              <div class="vx-row">
                <div class="vx-col md:w-2/12">
                <ValidationProvider 
                  v-slot="{ errors }" 
                  :name="item.house_function_title" 
                  :rules="item.is_required ? 'required' : ''">
                  <lh-input-number
                    v-model="formData.house_model_functions[item.id]"
                    class="w-full"
                  />
                  <span 
                    v-if="errors.length > 0" 
                    class="text-danger text-sm">
                    {{ errors[0] }}
                  </span>
                </ValidationProvider>
                </div>
                <div class="vx-col md:w-7/12 flex items-center">
                  {{ item.house_function_unit }}
                </div>
              </div>
            </div>
          </div>

          <vs-divider />

          <FormLayout>
            <div class="w-full border-t border-danger mb-4 px-10">
              ภาพแบบบ้าน (.JPG , .PNG , .GIF) กรุณาเลือกภาพที่ต้องการเป็นภาพหลัก 1 ภาพ
              <br >
              <span class="font-bold">ขนาดรูปจะต้องมีสัดส่วน (Ratio) 16 : 9</span>
              <br>
              <span class="text-warning">ขนาดรูปแนะนำ 1920 x 1080</span>
            </div>
            <div class="w-full px-10">
              <ValidationProvider
                v-slot="{ errors }"
                name="ภาพแบบบ้าน"
                rules="lh_ratio:16,9"
              >
                <GalleryForm
                  :folder="`${baseFolder}/house-model/${formData.house_model_folder_name}/House Image`"
                  v-model="formData.house_model_image"
                  has-alt-text
                  has-selectable
                  selection-name="house-model-image"
                  selectRadioLabel="เลือกเป็นภาพหลัก"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
          </FormLayout>

          <vs-divider />

          <FormLayout>
            <FormLabel>
              <span>
                ภาพ Floor Plan ชั้น 1
              </span>
              <br>
              <span class="font-bold">ขนาดรูปจะต้องมีสัดส่วน (Ratio) 16 : 9</span>
              <br>
              <span class="text-warning">ขนาดรูปแนะนำ 1920 x 1080</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="ภาพ Floor Plan ชั้น 1"
                rules="lh_ratio:16,9"
              >
                <ImageUpload
                  :folder="`${baseFolder}/house-model/${formData.house_model_folder_name}/Floor One`"
                  v-model="formData.house_model_floor_one_image"
                  :has-delete-button="!isEmpty(formData.house_model_floor_one_image)"
                  has-alt-text
                  selection-name="house_model_floor_one_image"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>

          <FormLayout>
            <FormLabel>
              <span>บรรยาย Floor Plan ชั้น 1</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="บรรยาย Floor Plan ชั้น 1">
                <vs-textarea
                  :class="{ 'border border-danger': errors.length > 0 }"
                  v-model="formData.house_model_floor_one_description"
                  rows="12"
                  class="w-full"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>

          <FormLayout>
            <FormLabel>
              <span>ภาพ Floor Plan ชั้น 2</span>
              <br>
              <span class="font-bold">ขนาดรูปจะต้องมีสัดส่วน (Ratio) 16 : 9</span>
              <br>
              <span class="text-warning">ขนาดรูปแนะนำ 1920 x 1080</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="ภาพ Floor Plan ชั้น 2"
                rules="lh_ratio:16,9"
              >
                <ImageUpload
                  :folder="`${baseFolder}/house-model/${formData.house_model_folder_name}/Floor Two`"
                  v-model="formData.house_model_floor_two_image"
                  has-alt-text
                  :has-delete-button="!isEmpty(formData.house_model_floor_two_image)"
                  selection-name="house_model_floor_two_image"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>

          <FormLayout>
            <FormLabel>
              <span>บรรยาย Floor Plan ชั้น 2</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="บรรยาย Floor Plan ชั้น 2">
                <vs-textarea
                  :class="{ 'border border-danger': errors.length > 0 }"
                  v-model="formData.house_model_floor_two_description"
                  class="w-full"
                  rows="12"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>

          <FormLayout>
            <FormLabel>
              <span>ภาพ Floor Plan ชั้น 3</span>
              <br>
              <span class="font-bold">ขนาดรูปจะต้องมีสัดส่วน (Ratio) 16 : 9</span>
              <br>
              <span class="text-warning">ขนาดรูปแนะนำ 1920 x 1080</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="ภาพ Floor Plan ชั้น 3"
                rules="lh_ratio:16,9"
              >
                <ImageUpload
                  :folder="`${baseFolder}/house-model/${formData.house_model_folder_name}/Floor Three`"
                  v-model="formData.house_model_floor_three_image"
                  has-alt-text
                  :has-delete-button="!isEmpty(formData.house_model_floor_three_image)"
                  selection-name="house_model_floor_three_image"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>

          <FormLayout>
            <FormLabel>
              <span>บรรยาย Floor Plan ชั้น 3</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="บรรยาย Floor Plan ชั้น 3">
                <vs-textarea
                  :class="{ 'border border-danger': errors.length > 0 }"
                  v-model="formData.house_model_floor_three_description"
                  class="w-full"
                  rows="12"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>

          <FormLayout>
            <FormLabel>
              <span>URL 360 Virtual Tour</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="URL 360 Virtual Tour">
                <vs-input
                  :class="{ 'border border-danger': errors.length > 0 }"
                  v-model="formData.house_model_image_360_url"
                  class="w-full"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>360 Cover image</span>
              <br >
              <span class="font-bold">ขนาดรูปจะต้องมีสัดส่วน (Ratio) 16 : 9</span>
              <br>
              <span class="text-warning">ขนาดรูปแนะนำ 1920 x 1080</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="house_model_image_360_thumbnail"
                rules="lh_mimes:jpg,png,gif|lh_ratio:16,9"
              >
                <ImageUpload
                  :folder="`${baseFolder}/house-model/${formData.house_model_folder_name}/Image 360`"
                  v-model="formData.house_model_image_360_thumbnail"
                  :has-delete-button="!isEmpty(formData.house_model_image_360_thumbnail)"
                  selection-name="house_model_image_360_thumbnail"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>

          <FormLayout>
            <FormLabel>
              <span>URL 3D Floor Plan</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="URL 3D Floor Plan"
              >
                <vs-input
                  :class="{ 'border border-danger': errors.length > 0 }"
                  v-model="formData.house_model_3d_floor_url"
                  class="w-full"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{
                    errors[0]
                  }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>
                เลือกภาพประกอบ 3D Floor Plan
                <br >(.jpg , .png , .gif)
                <br >
                <span class="font-bold">ขนาดรูปจะต้องมีสัดส่วน (Ratio) 16 : 9</span>
                <br>
                <span class="text-warning">ขนาดรูปแนะนำ 1920 x 1080</span>
              </span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="house_model_3d_floor_thumbnail"
                rules="lh_mimes:jpg,png,gif|lh_ratio:16,9"
              >
                <ImageUpload
                  v-model="formData.house_model_3d_floor_thumbnail"
                  :folder="`${baseFolder}/house-model/${formData.house_model_folder_name}/3D`"
                  :has-delete-button="!isEmpty(formData.house_model_3d_floor_thumbnail)"
                  selection-name="house_model_3d_floor_thumbnail"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{
                    errors[0]
                  }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>

          <FormLayout>
            <FormLabel>
              <span>เลือกชนิดการอัพโหลด</span>
            </FormLabel>
            <FormContent>
              <ul>
                <li>
                  <vs-radio
                    v-model="formData.house_model_video_type"
                    vs-name="radios1"
                    vs-value="youtube"
                  >VDO Youtube
                  </vs-radio>
                </li>
                <li>
                  <vs-radio
                    v-model="formData.house_model_video_type"
                    vs-name="radios1"
                    vs-value="file"
                  >VDO File</vs-radio
                  >
                </li>
              </ul>
            </FormContent>
          </FormLayout>
          <FormLayout v-show="formData.house_model_video_type === 'youtube'">
            <FormLabel>
              <span>URL Youtube</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                :rules="`${formData.house_model_video_type === 'youtube' ? 'url' : ''}`"
                name="URL Youtube"
              >
                <vs-input
                  v-model="formData.house_model_video_url"
                  class="w-full"
                  placeholder="URL Youtube"
                  name="house_model_video_url"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{
                    errors[0]
                  }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>Thumbnail(.jpg , .png , .gif)</span>
              <br >
              <span class="font-bold">ขนาดรูปจะต้องมีสัดส่วน (Ratio) 16 : 9</span>
              <br>
              <span class="text-warning">ขนาดรูปแนะนำ 1920 x 1080</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="house_model_video_thumbnail"
                rules="lh_mimes:jpg,png,gif|lh_ratio:16,9"
              >
                <ImageUpload
                  :folder="`${baseFolder}/house-model/${formData.house_model_folder_name}/VDO Thumbnail`"
                  v-model="formData.house_model_video_thumbnail"
                  :has-delete-button="!isEmpty(formData.house_model_video_thumbnail)"
                  selection-name="house_model_video_thumbnail"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout v-show="formData.house_model_video_type === 'file'">
            <FormLabel>
              <span>VDO (เฉพาะไฟล์ .mp4)</span>
              <br><span class="font-bold">ขนาดไฟล์ vdo ไม่เกิน 300 MB</span><br>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="Video"
                rules="lh_mimes:mp4|lh_size:300_MB"
              >
                <VdoUpload
                  :folder="`${baseFolder}/house-model/${formData.house_model_folder_name}/VDO`"
                  v-model="formData.house_model_video_file"
                  :has-delete-button="!isEmpty(formData.house_model_video_file)"
                  selection-name="assets"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>

          <vs-divider />

          <FormLayout>
            <div class="w-full px-10 mb-3 border-t border-danger">
              Gallery แบบบ้าน (.JPG , .PNG , .GIF) ใส่ภาพน้อยสุด 5 ภาพ
            </div>
            <div class="w-full px-10">
              <ValidationProvider
                v-slot="{ errors }"
                name="รูปภาพหลักของหน้า"
                rules="lh_ratio:16,9"
              >
                <GalleryForm
                  :folder="`${baseFolder}/house-model/${formData.house_model_folder_name}/Gallery`"
                  v-model="formData.house_model_gallery"
                  has-alt-text
                  selection-name="house-model-gallery"
                />
              <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
          </FormLayout>

          <vs-divider />
          <FormLayout>
            <FormLabel>
              <span>แสดงบนเว็ปไซต์</span><span class="text-danger"> *</span>
            </FormLabel>
            <div class="flex items-center vx-col w-full md:w-6/12">
              <vs-switch v-model="formData.status" />
            </div>
          </FormLayout>
        </form>
      </vx-card>
      <vx-card>
        <FormLayout>
          <FormLabel>
            <span>แก้ไขล่าสุดโดย</span>
          </FormLabel>
          <div class="flex items-center vx-col w-full md:w-6/12">
            <span class="mt-2"> {{formData.updated_by}}</span>
          </div>
        </FormLayout>
        <FormLayout>
          <FormLabel>
            <span>แก้ไขล่าสุดวันที่</span>
          </FormLabel>
          <div class="flex items-center vx-col w-full md:w-6/12">
            <span class="mt-2"> {{formatDate(formData.updated_at)}}</span>
          </div>
        </FormLayout>
        <FormLayout>
          <FormLabel />
          <FormContent>
            <vs-button
              @click="submit(context)">{{ btnSubmitLabel }}</vs-button>
          </FormContent>
        </FormLayout>
      </vx-card>
    </ValidationObserver>
  </div>
</template>
<script>
import { computed, watch } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import isEmpty from 'lodash/isEmpty'
// import orderBy from 'lodash/orderBy'
import remove from 'lodash/remove'
import orderBy from 'lodash/orderBy'

import ImageUpload from '@/components/upload/ImageUpload'
import VdoUpload from '@/components/upload/VdoUpload'
import GalleryForm from '@/components/GalleryForm'
import useCrud from '@/use/useCrud'
import useHouseModelUtil from '@/use/useHouseModelUtil'

import env from '@/env'

export default {
  name: 'HomeModelsView',
  components: {
    VdoUpload,
    ImageUpload,
    ValidationObserver,
    GalleryForm,
  },
  setup(props, ctx) {
    const crudForm = useCrud(ctx, 'houseModel')
    crudForm.routePrefix.value = 'property-plan-home-model'

    const { formData, serializeFormData, btnSubmitLabel } = useHouseModelUtil(ctx)

    const baseFolder = computed(() => env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER)

    const houseSeries = computed(() =>
      ctx.root.$store.state.houseSerie.items.filter(
        (item) => typeof item.house_serie_title !== 'undefined',
      ),
    )
    const propertyCategories = computed(() => ctx.root.$store.state.category.categories)
    const houseFunctionList = computed(() => {
      const houseFunctionList = ctx.root.$store.state.houseFunction.items.filter(
        (item) => typeof item.house_function_title !== 'undefined',
      )
      const orderFunction = orderBy(houseFunctionList, ['created_at'], ['asc'])

      const requireFunction = remove(orderFunction, (houseFunction) => houseFunction.is_required)

      const langList = ctx.root.$store.state.houseFunction.langItems

      // return orderBy(requireFunction, (item) => new Date(item.created_at), ['asc']).concat(orderBy(houseFunctionList, (item) => new Date(item.created_at), ['asc']))
      const listFunctions = requireFunction.concat(orderFunction)
      const currentLang = ctx.root.$store.state.locale.currentLang
      if (currentLang !== 'th') {
        return listFunctions.map((item) => {
          const itemTh = langList.find((itemlangFunction) => {
            return itemlangFunction.id === item.id
          })
          let title = item.house_function_title
          let unit = item.house_function_unit
          if (itemTh) {
            title = `${title} (${itemTh.house_function_title})`
            unit = `${unit} (${itemTh.house_function_unit})`
          }

          return {
            ...item,
            house_function_title: title,
            house_function_unit: unit
          }
        })
      }
      return listFunctions
    })

    const submit = (ctx) => {
      const serialzedData = serializeFormData(formData)
      crudForm.addItem(ctx, serialzedData)
    }

    watch(
      () => formData.house_model_image,
      (newValue) => {
        if (!isEmpty(newValue)) {
          const isSelected = newValue.some((value) => value.image_selected)
          // default first item
          if (!isSelected) formData.house_model_image[0].image_selected = true
        }
      },
    )

    return {
      ...crudForm,
      submit,
      formData,
      houseSeries,
      propertyCategories,
      houseFunctionList,
      isEmpty,
      baseFolder,
      btnSubmitLabel
    }
  },
}
</script>
